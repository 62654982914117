<template>
	<div>
		<div class="indexUser">
			<div>
				<!-- header  点击 -->
				<headers :userList="userList"></headers>
			</div>
			<div class="bg1 userBox">
				<div class="wrap problem">
					<div class="fl">
						<div v-for="(v, i) in categoryList" :key="i" :class="{active: state == i}">
							<p class="one" @click="changeTitle(v, i)">{{ v.category_name }}</p>
						</div>
					</div>
					<div class="fr bgw">
						<div v-for="(v, i) in list" :key="i">
							<p class="one title">{{ v.title }}</p>
							<div :ref="i" class="txt"></div>
						</div>
						<!-- <div>
              <p class="one title">问题1</p>
              <div class="txt">
                商品促销信息以商品详情页“促销”栏中的信息为准；商品的具体售价
                以订单结算页价格为准；如您发现活动商品售价或促销信息有异常，建议购
                买前先联系销售商咨询。
              </div>
            </div>-->
					</div>
				</div>
			</div>
			<div>
				<publicBottom></publicBottom>
			</div>
		</div>
	</div>
</template>
<script>
	import headers from "./header"; // 头部 TOP 和header
	import publicBottom from "@/components/public/public_bottom"; //底部
	import {
		usergetinfo
	} from "@/request/user";
	import {
		getArticleList
	} from "@/request/user";
	import {
		getCategoryList
	} from "@/request/user";
	export default {
		components: {
			headers,
			publicBottom
		},
		data() {
			return {
				userList: [], // 个人信息
				state: 0,
				categoryList: [],
				list: []
			};
		},
		created() {
			let datas = localStorage.getItem("lzyyToken");
			let Athis = this;
			if (datas != null) {
				this.$tokens = datas;
			} else {
				this.$message.error("登录错误，请重新登录");
				setTimeout(function() {
					Athis.$router.replace({
						path: "/land"
					});
				}, 1000);
			}

			this.init();
		},

		methods: {
			init() {
				//   个人信息
				usergetinfo({
					token: this.$tokens
				}).then(res => {
					this.userList = res.data;
				});
				this.categoryList = [];
				getCategoryList({
					token: this.$token
				}).then(res => {
					if (res.code == 1) {
						for (let item of res.data) {
							if (item.is_yongyao == 0) {
								this.categoryList.push(item);
							}
						}

						if (this.categoryList[0]) {
							this.getCategoryInfo(res.data[0].id);
						}
					}
				});
			},
			getCategoryInfo(id) {
				getArticleList({
					token: this.$token,
					category_id: id
				}).then(res => {
					if (res.code == 1) {
						this.list = res.data.data;
						this.$nextTick(() => {
							for (let prop in this.$refs) {
								if (this.list[prop])
									this.$refs[prop][0].innerHTML = this.list[prop].content;
							}
						});
					}
				});
			},
			changeTitle(v, i) {
				this.state = i;
				this.getCategoryInfo(v.id);
			}
		}
	};
</script>

<style lang="less" scoped>
	.txt {
		padding-left: 28px;
		padding-right: 28px;
		font-size: 22px;
		color: rgba(128, 128, 128, 1);
		padding-top: 24px;
	}

	.title {
		height: 86px;
		line-height: 86px;
		font-size: 22px;
		padding-left: 40px;
		font-weight: bold;
		border-bottom: 1px solid #e6e6e6;
	}

	.wrap {
		min-height: 820px;
		background: #f0f0f0;
	}

	.fr {
		padding-bottom: 60px;
		width: 80%;
		padding-left: 22px;
		padding-right: 22px;
		min-height: 820px;

	}

	.fl {
		padding-bottom: 60px;
		width: 20%;
		background: #f0f0f0;
		padding-bottom: 260px;
		min-height: 820px;

		div {
			height: 114px;
			line-height: 114px;
			text-align: center;
			font-size: 22px;
			color: #313131;
			font-family: Microsoft YaHei;
			font-weight: bold;
			border-bottom: 1px solid #e6e6e6;
		}

		p {
			cursor: pointer;
		}
	}

	.problem {
		overflow: hidden;
	}

	.active {
		background: #4b4b4b !important;
		color: white !important;
	}
</style>
